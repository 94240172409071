.sorttable th.up {
    background-image: url("./images/up_arrow.png");
}
.sorttable th.down {
    background-image: url("./images/down_arrow.png");
}
.sorttable th.default {
    background-image: url("./images/default.png");
}
.sorttable th.up,
th.default,
th.down {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center right;
}
  