.wmdeals-form {
    padding-left: 15px;
}

.is_loading_true {
    padding-left: 55px;
}

.wrap-anywhere {
    word-wrap: anywhere;
}